import { Component,Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../state.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit {

  constructor(
    private stateService: StateService,
    @Inject('Window') public window: Window
  ) {}

  ngOnInit(): void {
    this.stateService.startLoading();
    let url = this.window.location.href;
    url = url.replace('/#','');
    //users shouldn't be in this component, probably an old bookmark, we'll just redirect them to the server
    this.window.location.replace(url);
  }
}
