import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LabLocale } from '../reports.module';

@Component({
  selector: 'app-worklist',
  templateUrl: './worklist-form.component.html',
  styleUrls: ['./worklist-form.component.scss'],
})
export class WorklistFormComponent {
  currentLab;
  currentLocale;

  worklistReportForm = this.fb.group({
    testCodes: new FormControl<string>(
      { value: '', disabled: false },
      {
        nonNullable: false,
      }
    ),
  });

  constructor(private fb: FormBuilder, route: ActivatedRoute, private router: Router) {
    route.data.subscribe((data) => {
      const labLocale = data.labLocale as LabLocale;
      this.currentLab = labLocale.lab;
      this.currentLocale = labLocale.locale;
    });
  }

  submitEnabled(): boolean {
    return this.worklistReportForm.controls.testCodes.value?.trim().length > 0;
  }

  submit(): void {
    const returnsReplaced = this.worklistReportForm.controls.testCodes.value.replace(/\n/g, ' ');
    const testCodes = returnsReplaced.split(' ').filter((item) => encodeURI(item.trim()));
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/reports/worklist'], {
        queryParams: {
          lab: this.currentLab,
          locale: this.currentLocale,
          testCodes: testCodes.join(','),
        },
      })
    );
    window.open(`${url}`, '_blank');
  }
}
