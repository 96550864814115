<div id="static-header" data-cy="static-header">
  <img src="/assets/images/logo.jpg" title="Clouded Leopard" id="clouded-leopard-logo" alt="Clouded Leopard logo" />
</div>
<form name="_form" [formGroup]="form" class="wip-form-filter">
  <div luxLayout="row" luxLayoutAlign="start center">
    <div class="days-back">
      <label class="spot-form__label" for="days-back">{{ 'LABELS.DAYS_BACK' | translate }}</label>
      <div class="spot-form__select">
        <select #daysBack class="spot-form__select-input" id="days-back" data-cy="days-back" formControlName="daysBack">
          <option class="spot-form__select-option" *ngFor="let day of daysBackValues" [value]="day">
            {{ 'LABELS.DAYS_BACK_DAY.' + day | translate }}
          </option>
        </select>
        <div class="spot-form__select-inner"></div>
        <span class="spot-form__select-open-indicator">
          <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
            <title>caret-down</title>
            <use xlink:href="./assets/icons/spot_icons.svg#caret-down"></use>
          </svg>
        </span>
      </div>
    </div>

    <div class="sort-option">
      <label class="spot-form__label" for="sort-option">{{ 'LABELS.SORT_BY' | translate }}</label>
      <div class="spot-form__select">
        <select class="spot-form__select-input" id="sort-option" data-cy="sort-option" formControlName="sortOption">
          <option
            class="spot-form__select-option"
            *ngFor="let sortOption of sortOptions"
            [value]="sortOption.option">
            {{ sortOption.translation }}
          </option>
        </select>
        <div class="spot-form__select-inner"></div>
        <span class="spot-form__select-open-indicator">
          <svg class="spot-icon spot-form__select-open-icon" aria-labelledby="title">
            <title>caret-down</title>
            <use xlink:href="./assets/icons/spot_icons.svg#caret-down"></use>
          </svg>
        </span>
      </div>
    </div>
  </div>

  <fieldset class="labs spot-form__checkbox-group" role="group">
    <div class="column-selector" luxLayout="row">
      <legend class="spot-form__checkbox-group-label" [ngClass]="{ 'has-errors': form.controls.labs.invalid }">
        <abbr class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</abbr
        ><span class="spot--screenreader-only">Required Field.</span>{{ 'LABELS.LAB' | translate }}
      </legend>
      <span class="spot-form__field-error" role="alert" *ngIf="form.controls.labs.invalid">
        <span class="spot-form__field-error-text"> {{ 'ERRORS_AND_FEEDBACK.REQUIRED_FIELD' | translate }} </span>
      </span>
      <a href="javascript:void(0);" (click)="selectAllLabs()" class="select-all spot-link">{{
        'LABELS.SELECT_ALL' | translate
      }}</a>
    </div>
    <span class="spot-form__microcopy">{{
      'ERRORS_AND_FEEDBACK.LAB_SELECTION_DETAILS' | translate
    }}</span>
    <div luxLayout="row wrap" luxLayoutGap="1" formGroupName="labs">
      <label
        *ngFor="let lab of labs; index as i"
        data-cy="labs"
        luxLayout="row"
        luxFlexAlign="start"
        luxLayoutAlign="start center"
        luxFlex="33"
        class="spot-form__checkbox"
        for="{{ lab.name + i }}">
        <input
          id="{{ lab.name + i }}"
          type="checkbox"
          [attr.data-cy]="'lab-' + lab.id"
          [formControlName]="lab.id"
          class="spot-form__checkbox-input"
          aria-describedby="workspaces-error" />
        <span class="spot-form__checkbox-inner">
          <span class="spot-form__checkbox-visual">
            <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
              <title>checkmark</title>
              <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
            </svg>
            <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
              <title>checkbox-indeterminate</title>
              <use xlink:href="./assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
            </svg>
          </span>
          <span class="spot-form__checkbox-label">{{ lab.name }}</span>
        </span>
      </label>
    </div>
  </fieldset>

  <fieldset class="workspace spot-form__checkbox-group" role="group">
    <div luxLayout="row">
      <legend class="spot-form__checkbox-group-label" [ngClass]="{ 'has-errors': workspacesFormInvalid }">
        <abbr class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</abbr
        ><span class="spot--screenreader-only">Required Field.</span>{{ 'LABELS.WORKSPACE' | translate }}
      </legend>
      <ng-container *ngIf="workspacesFormInvalid">
        <span class="spot-form__field-error" id="workspaces-error" role="alert" *ngIf="workspacesForm.errors?.required">
          <span class="spot-form__field-error-text"> {{ 'ERRORS_AND_FEEDBACK.REQUIRED_FIELD' | translate }} </span>
        </span>
      </ng-container>
    </div>
    <span class="spot-form__microcopy" id="password-help-text">{{
      'ERRORS_AND_FEEDBACK.WORKSPACE_WARNING' | translate
    }}</span>

    <div luxLayout="row" *ngIf="workspaces.length">
      <div luxLayout="column" luxFlex="1 1 33%" class="spot-form__checkbox-group-inner">
        <ng-container *ngFor="let w of workspaces; index as i">
          <ng-container *ngIf="i < workspaces?.length / 3">
            <ng-container *ngTemplateOutlet="workspace; context: { index: i }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div luxLayout="column" luxFlex="1 1 33%" class="spot-form__checkbox-group-inner">
        <ng-container *ngFor="let w of workspaces; index as i">
          <ng-container *ngIf="i >= workspaces?.length / 3 && i < (workspaces?.length * 2) / 3">
            <ng-container *ngTemplateOutlet="workspace; context: { index: i }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div luxLayout="column" luxFlex="1 1 33%" class="spot-form__checkbox-group-inner">
        <ng-container *ngFor="let w of workspaces; index as i">
          <ng-container *ngIf="i >= (workspaces?.length * 2) / 3">
            <ng-container *ngTemplateOutlet="workspace; context: { index: i }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </fieldset>
  <div luxLayout="row" class="assay-selector-header">
    <p
      class="assay-selector-label"
      [ngClass]="{
        'has-errors': assaySelectorFormInvalid
      }">
      <abbr class="spot-form__label-required-indicator" title="This field is required." aria-hidden="true">*</abbr>
      <span class="spot--screenreader-only">Required Field.</span>{{ 'LABELS.ASSAY' | translate }}
    </p>
    <ng-container *ngIf="assaySelectorFormInvalid">
      <span
        class="spot-form__field-error"
        id="assays-selector-error"
        role="alert"
        *ngIf="assaysSelectorForm.errors?.required">
        <span class="spot-form__field-error-text"> {{ 'ERRORS_AND_FEEDBACK.REQUIRED_FIELD' | translate }} </span>
      </span>
    </ng-container>
  </div>
  <app-assays-selector
    data-cy="wip-assay-selector"
    [workspaces]="workspacesSelected"
    formControlName="assaysSelector"
    aria-describedby="assays-selector-error"></app-assays-selector>

  <fieldset class="columns spot-form__checkbox-group" role="group">
    <div class="column-selector" luxLayout="row">
      <legend class="spot-form__checkbox-group-label">{{ 'LABELS.COLUMN_SELECTOR' | translate }}</legend>
    </div>
    <span class="spot-form__microcopy" id="password-help-text">
      {{ 'ERRORS_AND_FEEDBACK.COLUMN_SELECTION_DETAILS' | translate }}
    </span>
    <div luxLayout="row wrap" luxLayoutGap="1" formGroupName="hiddenReportColumns">
      <div luxLayoutAlign luxFlex="33" *ngFor="let column of columns; index as i">
        <label data-cy="wip-column" class="spot-form__checkbox">
          <input
            type="checkbox"
            [formControlName]="column.column"
            (keydown.enter)="toggleColumn($event, column)"
            class="spot-form__checkbox-input" />
          <span class="spot-form__checkbox-inner">
            <span class="spot-form__checkbox-visual">
              <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
                <title>checkmark</title>
                <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
              </svg>
              <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
                <title>checkbox-indeterminate</title>
                <use xlink:href="./assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
              </svg>
            </span>
            <span class="spot-form__checkbox-label">{{ column.translation }}</span>
          </span>
        </label>
      </div>
    </div>
  </fieldset>

  <div class="toolbar" luxLayout="row" luxLayoutAlign="end center">
    <button
      type="submit"
      data-cy="submit-button"
      class="spot-button spot-button--primary"
      [ngClass]="{ 'spot-button--disabled': form.invalid }"
      [disabled]="form.invalid"
      (click)="submitForm()">
      {{ 'LABELS.SUBMIT' | translate }}
    </button>
    <button
      type="reset"
      data-cy="reset-button"
      class="spot-button spot-button--secondary"
      [ngClass]="{ 'spot-button--disabled': form.pristine }"
      [disabled]="form.pristine"
      (click)="resetForm()">
      {{ 'LABELS.RESET' | translate }}
    </button>
  </div>
  <ng-template #workspace let-i="index" let-formControl="formControl" formGroupName="workspaces">
    <label data-cy="wip-workspace" class="spot-form__checkbox" for="{{ workspaces[i].name + i }}">
      <input
        id="{{ workspaces[i].name + i }}"
        name="workspace"
        type="checkbox"
        [formControlName]="workspaces[i].workspaceCode"
        class="spot-form__checkbox-input"
        aria-describedby="workspaces-error"
        (keydown.enter)="toggleWorkspace($event, workspaces[i])" />
      <span class="spot-form__checkbox-inner">
        <span class="spot-form__checkbox-visual">
          <svg class="spot-icon spot-form__checkbox-checked-icon" aria-labelledby="title">
            <title>checkmark</title>
            <use xlink:href="./assets/icons/spot_icons.svg#checkmark"></use>
          </svg>
          <svg class="spot-icon spot-form__checkbox-indeterminate-icon" aria-labelledby="title">
            <title>checkbox-indeterminate</title>
            <use xlink:href="./assets/icons/spot_icons.svg#checkbox-indeterminate"></use>
          </svg>
        </span>
        <span class="spot-form__checkbox-label">{{ workspaces[i].name }}</span>
      </span>
    </label>
  </ng-template>
</form>
