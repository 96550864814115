import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { WipColumn } from './wip-form.model';
import { StaticData } from '../../../static-data';
import { STATIC_DATA } from '../../../init.service';
import { HttpClient } from '@angular/common/http';
import { LabLocale } from '../../reports.module';
import { UrlUtil } from '@lims-common-ux/lux';

@Injectable({
  providedIn: 'root',
})
export class WipColumnResolver  {
  constructor(@Inject(STATIC_DATA) private staticData: StaticData, private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<WipColumn>> {
    const labLocale: LabLocale = route.parent.data.labLocale;
    return this.http.get<Array<WipColumn>>(UrlUtil.interpolateUrl(this.staticData.wipReportColumns.href, labLocale));
  }
}
