import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { WipWorkspaceSpec } from './wip-form.model';
import { HttpClient } from '@angular/common/http';
import { StateService } from '../../../state.service';
import { LabLocale } from '../../reports.module';
import { StaticData } from '../../../static-data';
import { STATIC_DATA } from '../../../init.service';
import { UrlUtil } from '@lims-common-ux/lux';

@Injectable({ providedIn: 'root' })
export class WipWorkspaceResolver  {
  constructor(
    private httpClient: HttpClient,
    @Inject(STATIC_DATA)
    private staticData: StaticData,
    private stateService: StateService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<WipWorkspaceSpec>> {
    const labLocale: LabLocale = route.parent.data.labLocale;
    this.stateService.startLoading();
    const url = UrlUtil.interpolateUrl(this.staticData.workspaceSpecs.href, labLocale);
    return this.httpClient.get<Array<WipWorkspaceSpec>>(url);
  }
}
